const messages = {
  list: {
    id: 'ID',
    component: 'Komponent',
    name: 'Názov',
    description: 'Popis',
    enabled: 'Povolené',
    data: 'Údaje',
    created_at: 'Dátum vytvorenia',
    modified_at: 'Dátum poslednej zmeny',
    created_by: 'Vytvoril'
  },
  filter: {
    id: 'ID',
    component: 'Komponent',
    name: 'Názov',
    search: 'Hľadať'
  },
  id: 'ID',
  component: 'Komponent',
  name: 'Názov',
  description: 'Popis',
  enabled: 'Povolené',
  data: 'Údaje'
}

export default messages
