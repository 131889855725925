const messages = {
  list: {
    id: 'ID',
    site: 'Stránka',
    source_url: 'Zdrojová URL',
    destination_url: 'Cieľová URL',
    created_at: 'Dátum vytvorenia',
    modified_at: 'Dátum poslednej zmeny',
    created_by: 'Vytvoril'
  },
  filter: {
    site: 'Stránka',
    source_url: 'Zdrojová URL',
    destination_url: 'Cieľová URL',
    id: 'ID',
    search: 'Hľadať'
  },
  id: 'ID',
  site: 'Stránka',
  source_url: 'Zdrojová URL',
  destination_url: 'Cieľová URL'
}

export default messages
