const messages = {
  list: {
    id: 'ID',
    title: 'Názov',
    identifier: 'Identifikátor',
    site: 'Stránka'
  },
  filter: {
    id: 'ID',
    title: 'Názov',
    identifier: 'Identifikátor',
    site: 'Stránka',
    search: 'Hľadať'
  },
  site: 'Stránka',
  title: 'Názov',
  identifier: 'Identifikátor',
  poll: 'Anketa',
  identifier_validation: 'Musí byť jedinečný'
}

export default messages
