const messages = {
  list: {
    id: 'ID',
    title: 'Názov',
    name: 'Meno',
    site: 'Stránka'
  },
  filter: {
    id: 'ID',
    title: 'Názov',
    name: 'Meno',
    site: 'Stránka',
    search: 'Hľadať'
  },
  site: 'Stránka',
  title: 'Názov',
  name: 'Meno',
  name_validation: 'Musí obsahovať iba znaky \'a-z\' a \'-\' a musí byť jedinečné'
}

export default messages
