const messages = {
  list: {
    id: 'ID',
    name: 'Názov',
    description: 'Popis',
    enabled: 'Povolené',
    data: 'Dáta',
    created_at: 'Dátum vytvorenia',
    modified_at: 'Dátum poslednej zmeny',
    created_by: 'Vytvoril'
  },
  filter: {
    id: 'ID',
    name: 'Názov',
    search: 'Hľadať'
  },
  id: 'ID',
  name: 'Názov',
  description: 'Popis',
  enabled: 'Povolené',
  data: 'Dáta'
}

export default messages
