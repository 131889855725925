const messages = {
  title: 'Nastavenie váh',
  add: 'Pridať zobrazenie',
  save: 'Uložiť',
  group: 'Skupina',
  name: 'Názov',
  printQuality: 'Kvalita Printu',
  online: 'Kvalita Online',
  subscriptions: 'Predplatné',
  socialEngagement: 'Sociálne zapojenie',
  videoQuality: 'Kvalita Videa',
  articlesQuality: 'Kvalita článkov',
  videoQuantity: 'Kvantita Videa',
  periodFor: 'Obdobie za',
  defaultPeriod: 'Predvolené',
  tableListing: {
    printQuality: 'Kvalita Printu',
    pageViewsNormalized: 'Zobrazenia PV (*normalizované)',
    subscriptions: 'Predplatné',
    disqus: 'Disqus',
    fbShares: 'Facebook zdieľania',
    videoViews: 'Zobrazenia videí',
    videoPlaythrough: 'Prehranie videí',
    articlesQuantity: 'Množstvo článkov',
    videoQuantity: 'Množstvo videí',
    avgDailyUsers: 'Priemerný počet užívateľov (RUs)',
    avgTimeSpentPerUserArticle: 'Priemerný čas strávený v článku',
    avgArticleCompletion: 'Priemerné dočítanie článku',
    avgTimeSpentPerUserGallery: 'Priemerný čas strávený v galérii',
    avgGalleryCompletion: 'Priemerná dopozeranosť galérie',
    avgDailyUsersOrganic: 'Priemerná denná organická návštevnosť',
    longTermPageViewsNormalized: 'Počet Long-Term PageViews (*normalizované)',
    longTermVideoViews: 'Počet Long-term VideoViews'
  }
}

export default messages
