const messages = {
  list: {
    id: 'ID',
    site: 'Stránka',
    rubric: 'Rubrika',
    service: 'Služba',
    code: 'Kód',
    title: 'Názov'
  },
  filter: {
    site: 'Stránka',
    rubric: 'Rubrika',
    service: 'Služba',
    code: 'Kód',
    title: 'Názov',
    search: 'Hľadať'
  },
  id: 'ID',
  site: 'Stránka',
  rubric: 'Rubrika',
  service: 'Služba',
  code: 'Kód',
  title: 'Názov',
  error: {
    codeLimit: 'Kód musí mať presne {limit} znaky'
  }
}

export default messages
