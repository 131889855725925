const messages = {
  filter: {
    server: 'Server',
    type: 'Typ',
    originalUrl: 'Originálna URL',
    alias: 'Alias',
    validFrom: 'Platnosť od',
    validTo: 'Platnosť do'
  },
  id: 'Id',
  server: 'Server',
  originalUrl: 'Originálna URL',
  alias: 'Alias',
  aliasUrl: 'URL Alias',
  validFrom: 'Platnosť od',
  reservedUntil: 'Platnosť do',
  selectDate: 'Vyberte dátum',
  status: 'Stav',
  description: 'Poznámka',
  redirectType: 'Typ presmerovania',
  redirectTypes: {
    permanent: '301: Permanentné',
    temporary: '302: Dočasné'
  },
  type: 'Typ',
  aliasType: 'Alias / Presmerovanie URL',
  aliasTypes: {
    alias: 'Alias',
    redirect: 'Presmerovanie'
  },
  statuses: {
    new: 'Nový',
    active: 'Aktívne',
    inactive: 'Neaktívne',
    deleted: 'Zmazané'
  },
  audits: {
    date: 'Dátum',
    user: 'Používateľ',
    description: 'Popis akcie',
    create: 'Vytvorený alias',
    update: 'Upravený alias',
    delete: 'Odstránený alias'
  },
  errors: {
    nonExistingOriginalUrl: 'Musíte zadať platnú URL adresu (napr. URL adresu existujúceho článku)',
    invalidOriginalUrl: 'Musíte zadať platnú URL adresu'
  }
}

export default messages
