const messages = {
  relevance: 'Aktuálnosť',
  position: 'Pozícia',
  time: 'Čas',
  importance: 'Dôležitosť',
  score: 'Skóre',
  published_on_hp: 'Publikované na HP',
  published_on_hp_article: ' Publikované na HP / článok',
  article: 'Článok',
  all_slots: 'Všetky',
  approved: 'schválené',
  articles_missing: '2 články neboli doporučené.',
  modalDeleteMessage: 'Ste si istí, že chcete odstrániť článok z ponuky článkov HP?',
  department_articles_missing: 'Články zo strediska {department} neboli doposiaľ doručené.',
  base: 'Stredisko',
  contentBlock: 'Content Block',
  date: 'Dátum publikovania',
  disapprove: 'Odobrať',
  fb_shared: 'Pridané na FB',
  pageViews: 'PVs',
  slot_base: 'Slot - stredisko',
  site: 'Stránka',
  dateSince: 'Od kedy má byť článok na HP',
  dateUntil: 'Do kedy má byť článok na HP',
  buttons: {
    select_article: 'Vyber z HP ponuky',
    position_on_hp: 'Poziciovať na HP'
  },
  list: {
    headers: {
      title: 'Titulok',
      subTitle: 'Slot - Stredisko - Stránka - Rubrika',
      pv: 'PV`s',
      publishWeb: 'Dátum publikovania na webe',
      publishHp: 'Dátum publikovania',
      fb: 'Priradené na FB',
      actions: 'Akcie'
    }
  }
}

export default messages
