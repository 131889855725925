const messages = {
  title: 'Nastavenie váh',
  add: 'Pridať zobrazenie',
  save: 'Uložiť',
  group: 'Skupina',
  name: 'Názov',
  printQuality: 'Kvalita Printu',
  online: 'Kvalita Online',
  subscriptions: 'Predplatné',
  socialEngagement: 'Sociálne zapojenie',
  videoQuality: 'Kvalita Videa',
  articlesQuality: 'Kvalita článkov',
  videoQuantity: 'Kvantita Videa',
  tableListing: {
    printQuality: {
      printQualityA: 'Kvalita Printu A',
      printQualityB: 'Kvalita Printu B',
      printQualityC: 'Kvalita Printu C'
    },
    online: {
      pageViews: 'Zobrazenia stránok',
      engagedPageView: 'Započítané zobrazenia stránok'
    },
    subscriptions: {
      subscriptions: 'Predplatné'
    },
    socialEngagement: {
      disqus: 'Disqus',
      fbShares: 'Facebook zdieľania'
    },
    videoQuality: {
      videoViews: 'Zobrazenia videí',
      videoPlaythrough: 'Prehranie videí'
    },
    articlesQuality: {
      printQuantityP1Author: 'Množstvo printu P1 autor',
      printQuantityP1Agency: 'Množstvo printu P1 agentúra',
      printQuantityP2Author: 'Množstvo printu P2 autor',
      printQuantityP2Agency: 'Množstvo printu P2 agentúra',
      onlineAuthor: 'Autor online',
      fromPrint: 'Z printu',
      agency: 'Agentúra',
      republished: 'Prepublikovaný',
      quiz: 'Kvíz',
      photostory: 'Fotostory'
    },
    videoQuantity: {
      videoInternal: 'Video - interné',
      videoSlideshow: 'Video - slideshow',
      videoSocial: 'Video - sociálne',
      videoAgency: 'Video - agentúrne'
    }
  }
}

export default messages
