const messages = {
  create_menu_label: 'Vytvoriť menu',
  notify: {
    menu_created: 'Menu konfigurácia bola úspešne vytvorená',
    required_fields: 'Prosím, vyplňte všetky povinné polia'
  },
  form: {
    server: 'Server',
    menu_name: 'Názov menu',
    state: 'Stav'
  },
  type_value: {
    active: 'Aktívne',
    inactive: 'Neaktívne'
  },
  switch: {
    shown: 'Zobrazeno'
  },
  list: {
    id: 'ID',
    server: 'Server',
    menu_name: 'Názov menu'
  },
  itemmodal: {
    title_add: 'Pridať novú položku menu',
    title_edit: 'Upraviť položku menu',
    pinboard_title_add: 'Pridať novú položku pinboardu',
    pinboard_title_edit: 'Upraviť položku pinboardu',
    menu_structure_label: 'Umiestnenie v štruktúre',
    menu_pinboard_label: 'Umiestnenie v Pinboarde',
    menu_structure_menu: 'Menu (hlavná kategória)',
    title_label: 'Názov položky v menu',
    name_label: 'Nápoveda (Title)',
    accesskey_label: 'Zkratka',
    type_label: 'Druh odkazu',
    rubric_label: 'Rubrika',
    tag_label: 'Redakčný tag',
    geneea_tag_label: 'Geneea tag',
    special_label: 'Špeciál',
    theme_label: 'Téma',
    series_label: 'Seriál',
    article_label: 'Článok',
    article_placeholder: 'Začnite písať názov/titulok článku',
    url_label: 'URL',
    displayrestriction_label: 'Obmedziť na čas',
    displayfrom_label: 'Zobraziť od',
    displayto_label: 'Zobraziť do'
  },
  pinboard: {
    main_menu: 'Pinboard v hlavnom menu',
    popup: 'Pinboard Popup',
    both: 'Oba Pinboardy'
  },
  menu: 'Menu',
  pinboard_title: 'Pinboard',
  add_item: 'Pridať položku',
  column: 'Stĺpec',
  drag_label: 'Presunúť',
  modal_copy: {
    title: 'Vytvorie kópie menu',
    text: 'Chcete vytvoriť kópiu zvolenej menu konfigurácie?'
  },
  type: {
    rubric: 'Rubrika',
    tag: 'Tag - redakční',
    geneeaTag: 'Tag - Geneea',
    specialArticleGroup: 'Speciál',
    theme: 'Téma',
    series: 'Seriál',
    article: 'Článek',
    url: 'Odkaz',
    level_2: '2. úroveň',
    level_3: '3. úroveň'
  },
  filter: {
    server: 'Server',
    menu_name: 'Názov menu',
    state: 'Stav',
    filter_label: 'Filtrovať'
  },
  linktype: {
    rubric: 'Rubrika',
    tag: 'Tag - redakčný',
    geneeaTag: 'Tag - Geneea',
    specialArticleGroup: 'Špeciál',
    theme: 'Téma',
    series: 'Seriál',
    article: 'Článok',
    url: 'Libovolná URL'
  }
}

export default messages
