const messages = {
  list: {
    id: 'Id',
    title: 'Titulok',
    slug: 'Slug',
    isVirtual: 'Virtuálna rubrika',
    site: 'Stránka',
    parent: 'Rodič',
    created_at: 'Dátum vytvorenia',
    modified_at: 'Dátum poslednej zmeny',
    created_by: 'Vytvoril',
    absolute_url: 'Absolútna URL',
    sorting: 'Zoradenie subrubrík'
  },
  filter: {
    title: 'Titulok',
    id: 'Id',
    slug: 'Slug',
    site: 'Stránka',
    parent: 'Rodič',
    search: 'Hľadať'
  },
  id: 'Id',
  title: 'Titulok',
  slug: 'Slug',
  textForSlug: 'Text pre Slug',
  site: 'Stránka',
  parent: 'Rodič',
  iptcCategory: 'IPTC kategórie',
  meta_title: 'Meta titulok',
  meta_description: 'Meta popis',
  meta_keywords: 'Meta kľúčové slová',
  setting_advertCategory: 'ITM oblasť parameter (reklamná kategória)',
  setting_chartbeatSection: 'Sekcia Chartbeat',
  setting_gemiusId: 'Gemius Id',
  setting_prRubric: 'PR rubrika',
  setting_includeInMenuSettings: 'Ponúkať v Menu editore',
  setting_special: 'Špeciálna rubrika',
  setting_advertSection: 'Sekcia jednotky pre Google reklamy (reklamná sekcia)',
  sportTable_seasonId: 'Id sezóny športovej tabuľky',
  sportTable_activeTab: 'Aktívna karta športovej tabuľky (prvá = 1)',
  rubric_without_articles: 'Rubrika bez článkov (zakázať možnosť nastaviť túto rubriku pre články, používa sa pre vlastné zoznamové rubriky)',
  hiddenInMenu: 'Skryté v automaticky generovanom menu',
  isArticleToppingEnabled: 'Topovať články',
  description: 'Popis rubriky',
  heroImage: 'Hero image (dostupný formát 16:5)',
  miniatureImage: 'Miniatúra (dostupný formát 3:2)',
  isVirtualized: 'Virtuálna rubrika',
  virtualized: 'Virtualizovať',
  unvirtualized: 'Zrušiť virtualizáciu',
  cannotVirtualize: 'Rubriku nebolo možné virtualizovať, pretože je v kolízii s týmito rubrikami: ',
  movingForbidden: 'Presun virtuálnej rubriky {rubricId} je zakázaný.',
  sorting: 'Zoradenie subrubrík',
  product: 'Produkt',
  enableVirtualizationMsg: 'Naozaj chcete virtualizovať rubriku?',
  disableVirtualizationMsg: 'Naozaj chcete zrušiť virtualizáciu rubriky?',
  conflictData: 'Rubrika koliduje s rubrikou:',
  rubricId: 'Id rubriky',
  rubricTitle: 'Názov rubriky',
  groupRubricIds: 'Skupina rovnakých rubrík',
  typeToSearch: 'Zadajte minimálne {min} znakov pre vyhľadanie',
  empty_result: 'Žiadne výsledky',
  shareToRubric: 'Zdieľať do rubriky',
  defaultPosition: 'Poziciovať v rubrike',
  dateSince: 'Poziciovať v rubrike od',
  dateUntil: 'Poziciovať v rubrike do',
  showUntil: 'Aktuálnosť článku',
  please_select_the_daily: 'Prosím vyberte denník',
  positionInRubric: 'Pozícia v rubrike'
}

export default messages
