const messages = {
  list: {
    userId: 'ID',
    username: 'Užívateľské meno',
    responseStatus: 'Stav odpovede',
    created_at: 'Dátum vytvorenia',
    clientIp: 'IP',
    adminVersion: 'Verzia administrátora'
  },
  filter: {
    userId: 'ID',
    username: 'Užívateľské meno',
    responseStatus: 'Stav odpovede',
    created_at_from: 'Dátum vytvorenia od',
    created_at_to: 'Dátum vytvorenia do',
    clientIp: 'IP',
    search: 'Hľadať'
  }
}

export default messages
