const messages = {
  id: 'Id',
  title: 'Nadpis',
  rubrics: 'Rubriky',
  search: 'Hľadaj',
  mainProduct: 'Hlavný produkt',
  loading_err: 'Chyba pri načítaní produktov'
}

export default messages
