const messages = {
  list: {
    id: 'Id',
    server: 'Server',
    title: 'Názov kvízu',
    type: 'Pravidla',
    loggedInUsersOnly: 'Len prihlásení'
  },
  filter: {
    id: 'Id',
    server: 'Server',
    title: 'Názov kvízu'
  },
  id: 'Id',
  server: 'Server',
  title: 'Názov kvízu',
  description: 'Text kvízu',
  type: 'Pravidla',
  image: 'Obrázok kvízu',
  resultsHeader: 'Hodnotenia',
  resultTitle: 'Text hodnotenia',
  addResult: 'Pridať hodnotenie',
  resultScore: 'Body',
  displayCorrectAnswersImmediatelyLabel: 'Kedy zobraziť správne odpovede?',
  displayCorrectAnswersImmediatelyLabelFalse: 'Po dokončení',
  displayCorrectAnswersImmediatelyLabelTrue: 'Ihneď',
  loggedInUsersOnlyLabel: 'Len pre prihlásených užívateľov',
  loggedInUsersOnlyLabelFalse: 'Nie',
  loggedInUsersOnlyLabelTrue: 'Áno',
  enabled: 'Aktívovať',
  questionsEmptyError: 'Kvíz musí obsahovať aspoň 1 otázku',
  isContest: 'Súťažný kvíz',
  contestId: 'ID súťaže (zo súťažnej aplikácie)',
  contestSettings: 'Nastavenia súťaže',
  quizType: {
    correctAnswer: 'Položka môže byť správna alebo chybná',
    points: 'Každá položka má bodovú hodnotu'
  },
  question: {
    add: 'Pridať otázku',
    header: 'Otázky',
    title: 'Text otázky',
    image: 'Obrázok otázky',
    article: 'Článok s nápovedou'
  },
  answer: {
    add: 'Pridať odpoveď',
    header: 'Odpovede',
    title: 'Text odpovede',
    points: 'Body',
    pointsTrue: 'pravda',
    pointsFalse: 'nepravda'
  },
  articleModal: {
    selectButton: 'Vybrať článok',
    header: 'Vybrať článok'
  }
}

export default messages
